import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchResult } from '../../../models/searchResult';

export type SearchCriteria = {
  projectTitle: string;
  projectId: string;
  region: string;
  customerName: string;
  quoteId: string;
  rtaNumber: string;
  fieldSalesOrder: string;
  jobSalesOrder: string;
  createDateOption: string;
  statusOption: string;
  amount: number;
  skip: number;
};

export interface SearchState {
  value: {
    loading: boolean;
    showResults: boolean;
    refreshSearch: boolean;
    searchCurrentPage: number;
    searchStartPage: number;
    searchEndPage: number;
    updateSearchPagesRange: boolean;
    filtersSelected: boolean;
    searchCriteriaChanged: boolean;
    search: SearchCriteria;
    searchResult: {
      projects: ISearchResult[];
      resultsStart: number;
      resultsEnd: number;
      totalResults: number;
    };
  };
}

export const defaultSearchCriteria = {
  projectTitle: '',
  projectId: '',
  region: '',
  customerName: '',
  quoteId: '',
  rtaNumber: '',
  fieldSalesOrder: '',
  jobSalesOrder: '',
  createDateOption: 'All',
  statusOption: 'All',
  amount: 5,
  skip: 0,
};

const initialState: SearchState = {
  value: {
    loading: false,
    showResults: false,
    refreshSearch: false,
    searchCurrentPage: 1,
    searchStartPage: 1,
    searchEndPage: 1,
    filtersSelected: false,
    searchCriteriaChanged: true, // Allow searching on first load
    search: defaultSearchCriteria,
    updateSearchPagesRange: true,
    searchResult: {
      projects: [],
      resultsStart: 0,
      resultsEnd: 0,
      totalResults: 0,
    },
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchLoading(state, action: PayloadAction<boolean>) {
      state.value.loading = action.payload;
    },
    clearSearchCriteria: (state) => {
      state.value.search = defaultSearchCriteria;
    },
    setProjectTitle(state, action: PayloadAction<string>) {
      state.value.search.projectTitle = action.payload;
    },
    setProjectId(state, action: PayloadAction<string>) {
      state.value.search.projectId = action.payload;
    },
    setRegion(state, action: PayloadAction<string>) {
      state.value.search.region = action.payload;
    },
    setCustomerName(state, action: PayloadAction<string>) {
      state.value.search.customerName = action.payload;
    },
    setQuoteId(state, action: PayloadAction<string>) {
      state.value.search.quoteId = action.payload;
    },
    setRtaNumber(state, action: PayloadAction<string>) {
      state.value.search.rtaNumber = action.payload;
    },
    setFieldSalesOrder(state, action: PayloadAction<string>) {
      state.value.search.fieldSalesOrder = action.payload;
    },
    setJobSalesOrder(state, action: PayloadAction<string>) {
      state.value.search.jobSalesOrder = action.payload;
    },
    setCreateDateOption(state, action: PayloadAction<string>) {
      state.value.search.createDateOption = action.payload;
    },
    setStatusOption(state, action: PayloadAction<string>) {
      state.value.search.statusOption = action.payload;
    },
    setAmount(state, action: PayloadAction<number>) {
      state.value.search.amount = action.payload;
    },
    setSkip(state, action: PayloadAction<number>) {
      state.value.search.skip = action.payload;
    },
    setSearchResults(
      state,
      action: PayloadAction<{
        projects: ISearchResult[];
        resultsStart: number;
        resultsEnd: number;
        totalResults: number;
      }>,
    ) {
      state.value.showResults = true;
      state.value.searchResult = action.payload;
    },
    clearSearchResults(state) {
      state.value.searchResult = {
        projects: [],
        resultsStart: 0,
        resultsEnd: 0,
        totalResults: 0,
      };
    },
    setShowResults(state, action: PayloadAction<boolean>) {
      state.value.showResults = action.payload;
    },
    setRefreshSearch(state, action: PayloadAction<boolean>) {
      state.value.refreshSearch = action.payload;
    },
    setSearchCurrentPage(state, action: PayloadAction<number>) {
      state.value.searchCurrentPage = action.payload;
    },
    setSearchStartPage(state, action: PayloadAction<number>) {
      state.value.searchStartPage = action.payload;
    },
    setSearchEndPage(state, action: PayloadAction<number>) {
      state.value.searchEndPage = action.payload;
    },
    setFiltersSelected(state, action: PayloadAction<boolean>) {
      state.value.filtersSelected = action.payload;
    },
    setSearchCriteriaChanged(state, action: PayloadAction<boolean>) {
      state.value.searchCriteriaChanged = action.payload;
    },
    setPagesRangeUpdateRequired(state, action: PayloadAction<boolean>) {
      state.value.updateSearchPagesRange = action.payload;
    },
  },
});

export const {
  setSearchLoading,
  clearSearchCriteria,
  setProjectTitle,
  setProjectId,
  setRegion,
  setCustomerName,
  setQuoteId,
  setRtaNumber,
  setFieldSalesOrder,
  setJobSalesOrder,
  setCreateDateOption,
  setStatusOption,
  setAmount,
  setSkip,
  setSearchResults,
  clearSearchResults,
  setShowResults,
  setRefreshSearch,
  setSearchCurrentPage,
  setFiltersSelected,
  setSearchCriteriaChanged,
  setSearchStartPage,
  setSearchEndPage,
  setPagesRangeUpdateRequired,
} = searchSlice.actions;

export default searchSlice.reducer;
