import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortTypes } from '../../../api/endpoints/projects';
import { ProjectItem } from '../../../types/ProjectTypes';
import { HOME_PROJECTS_LOADING_STEP } from '../../../constants/home';

export interface HomeState {
  value: {
    loading: boolean;
    summaryHeaderInfo: {
      totalProjects: string;
      activeTotalJobs: string;
    };
    projects: ProjectItem[];
    projectsPagination: {
      resultsStart: number;
      resultsEnd: number;
      totalResults: number;
    };
    projectsConfig: {
      skipProjectAmt: number;
      sortOption: {
        key: string;
        title: string;
        config: {
          sortType: string;
          reverseOrder: boolean;
        };
      };
      selectedCountry: string;
      selectedYear: number | null;
      reverseOrder: boolean;
      searchValue: string;
      projectsShownAmount: number;
    };
    hideableFilter: {
      selectedGroup: string | null;
      selectedFilterName: string | null;
      expandedGroups: Array<string>;
      filtersOpen: boolean;
    };
    sidebar: {
      countries: Array<string>;
      projectsCountByYear: [string, number][];
    };
  };
}

const initialState: HomeState = {
  value: {
    loading: false,
    summaryHeaderInfo: {
      totalProjects: '0',
      activeTotalJobs: '0',
    },
    projects: [
      // {
      //   projectname:
      //     'Project Name Project Name Project Name Project Name Project Name Project Name Project Name Project Name Project Name Project Name',
      //   wells: [
      //     'Well 01',
      //     'Well 02',
      //     'Well 03',
      //     'Well 04',
      //     'Well 05',
      //     'Well 06',
      //     'Well 07',
      //     'Well 08',
      //     'Well 09',
      //   ],
      //   projectId: '000000',
      //   customerName: 'John Doe',
      //   region: 'R15_Well_19',
      //   siteAddress: 'Ipsum, Lorem',
      //   estimatedOnSiteDate: '2019-11-14T00:55:31.820Z',
      //   quoteId: 'EG-110057418-2024-00-00',
      //   fieldSalesOrder: '0908227584',
      //   statusCharts: {
      //     health: {
      //       healthy: 30,
      //       atRisk: 8,
      //       issues: 6,
      //     },
      //     progressPercentage: 25,
      //   },
      //   salesOrders: [
      //     {
      //       notificationCount: 2,
      //       soNum: '905269999',
      //       name: 'IRVING J UNIT 550H',
      //       ploNum: '12345',
      //       lastModified: '10/10/2021',
      //       health: 'Healthy',
      //       controlPoint: 'Validated',
      //       stage: 'Operations',
      //       type: 'Service',
      //     },
      //     {
      //       notificationCount: 1,
      //       soNum: '906244711',
      //       name: 'IRVING J UNIT 550H',
      //       ploNum: '12345',
      //       lastModified: '10/10/2021',
      //       health: 'Healthy',
      //       controlPoint: 'Validated',
      //       stage: 'Operations',
      //       type: 'Service',
      //     },
      //     {
      //       notificationCount: 1,
      //       soNum: '905269880',
      //       name: 'IRVING J UNIT 550H',
      //       ploNum: '12345',
      //       lastModified: '10/10/2021',
      //       health: 'Healthy',
      //       controlPoint: 'Validated',
      //       stage: 'Operations',
      //       type: 'Manufacturing',
      //     },
      //   ],
      // },
      //
    ],
    projectsPagination: {
      resultsStart: 0,
      resultsEnd: 0,
      totalResults: 0,
    },
    projectsConfig: {
      skipProjectAmt: 0,
      sortOption: {
        key: 'last-mod-newest-to-oldest',
        title: 'Last Modified Newest to Oldest',
        config: {
          sortType: sortTypes.LAST_MOD_DATE,
          reverseOrder: true,
        },
      },
      reverseOrder: true,
      searchValue: '',
      selectedCountry: '',
      selectedYear: null,
      projectsShownAmount: HOME_PROJECTS_LOADING_STEP,
    },
    hideableFilter: {
      selectedGroup: null,
      expandedGroups: [],
      filtersOpen: false,
      selectedFilterName: null,
    },
    sidebar: {
      countries: [],
      projectsCountByYear: [],
    },
  },
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.value.loading = action.payload;
    },
    setSummaryHeader: (state, action) => {
      state.value.summaryHeaderInfo = action.payload;
    },
    setProjects: (state, action: PayloadAction<ProjectItem[]>) => {
      state.value.projects = action.payload;
    },
    appendProjects: (state, action: PayloadAction<ProjectItem[]>) => {
      if (
        state.value.projects.some((pr1) =>
          action.payload.some((pr2) => pr1.projectId === pr2.projectId),
        )
      ) {
        return;
      }
      state.value.projects = state.value.projects.concat(action.payload);
    },
    clearProjects: (state) => {
      state.value.projects.length = 0;
    },
    setProjectsPagination: (state, action) => {
      state.value.projectsPagination = action.payload;
    },
    updateProjectsConfigSkip: (state) => {
      state.value.projectsConfig.skipProjectAmt = state.value.projectsConfig.projectsShownAmount;
    },
    setProjectsConfigSkip: (state, action) => {
      state.value.projectsConfig.skipProjectAmt = action.payload;
    },
    setProjectsConfigSortOption: (state, action) => {
      state.value.projectsConfig.sortOption = action.payload;
    },
    setProjectsConfigReverseOrder: (state, action) => {
      state.value.projectsConfig.reverseOrder = action.payload;
    },
    setProjectsConfigSearchValue: (state, action) => {
      state.value.projectsConfig.searchValue = action.payload;
    },
    setProjectsConfigSelectedYear: (state, action) => {
      state.value.projectsConfig.selectedYear = action.payload;
    },
    setProjectsConfigSelectedCountry: (state, action) => {
      state.value.projectsConfig.selectedCountry = action.payload;
    },
    setCollapsableFilter: (
      state,
      action: PayloadAction<{
        selectedGroup: string | null;
        selectedFilterName: string | null;
      }>,
    ) => {
      state.value.hideableFilter.selectedGroup = action.payload.selectedGroup;
      state.value.hideableFilter.selectedFilterName = action.payload.selectedFilterName;
    },
    setGroupExpanded: (state, action) => {
      const existingIdx = state.value.hideableFilter.expandedGroups.indexOf(action.payload);
      if (existingIdx === -1) {
        state.value.hideableFilter.expandedGroups.push(action.payload);
      }
    },
    setGroupCollapsed: (state, action) => {
      const idxToDelete = state.value.hideableFilter.expandedGroups.indexOf(action.payload);
      if (idxToDelete !== -1) {
        delete state.value.hideableFilter.expandedGroups[idxToDelete];
      }
    },
    closeHideableFilters: (state) => {
      state.value.hideableFilter.filtersOpen = false;
    },
    openHideableFilters: (state) => {
      state.value.hideableFilter.filtersOpen = true;
    },
    toggleHideableFilters: (state) => {
      state.value.hideableFilter.filtersOpen = !state.value.hideableFilter.filtersOpen;
    },
    increaseProjectsShownAmount: (state) => {
      state.value.projectsConfig.projectsShownAmount += HOME_PROJECTS_LOADING_STEP;
    },
    setProjectsShownAmount: (state, action: PayloadAction<number>) => {
      state.value.projectsConfig.projectsShownAmount = action.payload;
    },
    setSidebar: (
      state: HomeState,
      action: PayloadAction<{
        countries: Array<string>;
        projectsCountByYear: [string, number][];
      }>,
    ) => {
      state.value.sidebar = action.payload;
    },
  },
});

export const {
  setLoading,
  setSummaryHeader,
  setProjects,
  appendProjects,
  clearProjects,
  setProjectsPagination,
  updateProjectsConfigSkip,
  setProjectsConfigSkip,
  setProjectsConfigSortOption,
  setProjectsConfigReverseOrder,
  setProjectsConfigSearchValue,
  setProjectsConfigSelectedYear,
  setProjectsConfigSelectedCountry,
  setCollapsableFilter,
  setGroupCollapsed,
  setGroupExpanded,
  closeHideableFilters,
  openHideableFilters,
  toggleHideableFilters,
  increaseProjectsShownAmount,
  setProjectsShownAmount,
  setSidebar,
} = homeSlice.actions;

export default homeSlice.reducer;
