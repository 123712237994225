import React from 'react';

import MoreInfoTooltip from './MoreInfoTooltip';
import { useAppSelector } from '../../features/state/hooks';

interface SummaryBarProps {
  salesOrderId: string;
}

const SummaryBar = (props: SummaryBarProps) => {
  return (
    <div className="summary-bar-container">
      <h1>SO# {props.salesOrderId}</h1>
    </div>
  );
};

interface SummaryDetailsProps {
  customerName: string;
  dosNumber: string;
  jobName: string;
  wellName: string;
  location: string;
  riskFactor: string;
  tierLevel: string;
  jobType: string;
  ploNumber: string;
  jobStatusCode: string;
  productOrService: string;
  projectDistributionList: string;
  controlPoint: string;
  jobContacts: string;
}

const SummaryDetails = (props: SummaryDetailsProps) => {
  return (
    <div className="summary-details-container">
      <h1>Job details</h1>
      <div className="details-container">
        <div className="details-column">
          <div className="details-item">
            <h2>Design of service number</h2>
            <p>{props.dosNumber}</p>
          </div>
          <div className="details-item">
            <h2>Job name</h2>
            <p>{props.jobName}</p>
          </div>
          <div className="details-item">
            <h2>Well name</h2>
            <p>{props.wellName}</p>
          </div>
          <div className="details-item">
            <h2>Customer</h2>
            <p>{props.customerName}</p>
          </div>
        </div>
        <div className="details-column">
          <div className="details-item">
            <h2>Location</h2>
            <p>{props.location}</p>
          </div>
          <div className="details-item">
            <h2>
              Risk factor
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Risk Factor.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.riskFactor}</p>
          </div>
          <div className="details-item">
            <h2>
              Tier level
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Tier Level.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.tierLevel}</p>
          </div>
          <div className="details-item">
            <h2>
              Job type
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Job Type.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.jobType}</p>
          </div>
        </div>
        <div className="details-column">
          <div className="details-item">
            <h2>PLO number</h2>
            <p>{props.ploNumber}</p>
          </div>
          <div className="details-item">
            <h2>Status</h2>
            <p>{props.jobStatusCode}</p>
          </div>
          <div className="details-item">
            <h2>
              Product or services
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Product or Services.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.productOrService}</p>
          </div>
          <div className="details-item">
            <h2>
              Project distribution list
              <MoreInfoTooltip
                className="completion-more-info"
                color={'black'}
                tooltipComponent={<span>This is the Project Distribution List.</span>}
                tooltipWidth={215}
              />
            </h2>
            <p>{props.projectDistributionList}</p>
          </div>
        </div>
        <div className="details-column">
          <div className="details-item">
            <h2>Control point</h2>
            <p>{props.controlPoint}</p>
          </div>
          <div className="details-item">
            <h2>Job contact</h2>
            <p>{props.jobContacts}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Summary = () => {
  const projectSummary = useAppSelector((state) => state.project.value.projectSummary);

  return (
    <div className="sales-order-summary-container">
      <SummaryBar salesOrderId={projectSummary.soNumber} />
      <SummaryDetails
        customerName={projectSummary.customerName}
        dosNumber={projectSummary.dosNumber}
        jobName={projectSummary.jobName}
        wellName={projectSummary.wellName}
        location={projectSummary.location}
        riskFactor={projectSummary.riskFactor}
        tierLevel={projectSummary.tierLevel}
        jobType={projectSummary.jobType}
        ploNumber={projectSummary.ploNumber}
        jobStatusCode={projectSummary.jobStatusCode}
        productOrService={projectSummary.productOrService}
        projectDistributionList={projectSummary.projectDistributionList}
        controlPoint={projectSummary.controlPoint}
        jobContacts={projectSummary.jobContacts}
      />
    </div>
  );
};

export default Summary;
