/* eslint-disable no-console */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Summary from '../components/common/Summary';
import { useApi } from '../api/ApiContext';
import { useAppDispatch } from '../features/state/hooks';
import { getProjectSummaryForSo } from '../api/endpoints/salesOrder';
import { setProjectSummary, reset } from '../features/state/slices/projectSlice';
import { useOktaAuth } from '@okta/okta-react';
import { constructProjectIdRoute } from '../utils/routing';

const Job = () => {
  const { apiInstance } = useApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { projectId, soNumber } = useParams();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    // When the user navigates directly to a job url
    if (!apiInstance.oktaInitialized) {
      return;
    }

    getProjectSummaryForSo(apiInstance, soNumber as string)
      .then((res) => {
        const projectSummary = res.responseAsJson;
        dispatch(setProjectSummary(projectSummary));
      })
      .catch((e) => {
        console.log('Failed to get SO summary', e);
        if (authState?.isAuthenticated && apiInstance.oktaInitialized) {
          if (!e.ok) {
            console.log('SalesOrder: Redirecting back to project, invalid response');
            navigate(constructProjectIdRoute(projectId));
          }
        }
      });
  }, [projectId, soNumber, dispatch, navigate, authState?.isAuthenticated, apiInstance]);

  return (
    <div className="job-container">
      <aside className="job">
        <span className="back" onClick={() => navigate(constructProjectIdRoute(projectId))}>
          Back
        </span>
      </aside>
      <section className="job">
        <Summary />
      </section>
    </div>
  );
};

export default Job;
