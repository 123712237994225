import {
  CollapsableGroupListData,
  CollapsableGroupData,
  CollapsableEntity,
} from '../../types/CollapsableFilterTypes';
import CollapsableFilterGroup from './CollapsableFilterGroup';
import NonCollapsableFilterPseudoGroup from './NonCollapsableFilterPseudoGroup';
import { useWindowSize } from '../../utils/hooks/useWindowSize';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import { closeHideableFilters } from '../../features/state/slices/homeSlice';
import SearchAndSortPanel from '../common/SearchAndSortPanel';
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react';
import { useEffect, useRef } from 'react';

const CollapsableFilterGroupList = ({
  groupListData,
  onSortChange,
  onSearchChange,
}: {
  groupListData: CollapsableGroupListData;
  onSortChange: (sortOption: { config: { reverseOrder: any } }) => void;
  onSearchChange: (searchValue: string) => void;
}) => {
  const { isMobile } = useWindowSize();
  const { filtersOpen, expandedGroups } = useAppSelector(
    (state) => state.home.value.hideableFilter,
  );
  const dispatch = useAppDispatch();
  const overlayscrollbarsRef = useRef<OverlayScrollbarsComponentRef>(null);

  // next forced overlayscrollbars update required since it can't automatically track
  // the block height changes if they caused by changing grid-template-rows property
  // timeout time is equal to the height animation time
  useEffect(() => {
    if (overlayscrollbarsRef.current && overlayscrollbarsRef.current.osInstance()) {
      setTimeout(() => {
        overlayscrollbarsRef.current!.osInstance()!.update(true);
      }, 1000);
    }
  }, [expandedGroups]);

  const componentOutput = (
    <nav className="aside-collapsable-filter-group-list">
      <OverlayScrollbarsComponent
        defer
        ref={overlayscrollbarsRef}
        options={{
          scrollbars: {
            visibility: 'hidden',
          },
          overflow: {
            x: 'hidden',
          },
        }}>
        <div className="aside-container">
          {isMobile && (
            <>
              <button
                className="aside-filters-close-button"
                onClick={() => dispatch(closeHideableFilters())}
              />
              <div className="aside-filter-section-group">
                <span className="aside-filter-section-group-header mobile-filters-group-header">
                  Filters
                </span>
                <SearchAndSortPanel onSearchChange={onSearchChange} onSortChange={onSortChange} />
              </div>
            </>
          )}

          {Object.entries(groupListData).map(([groupName, groupData], idx) =>
            Array.isArray(groupData) ? (
              <NonCollapsableFilterPseudoGroup
                filterGroupData={groupData as Array<CollapsableEntity>}
                listHeader={groupName}
                key={`collapsable-filter-group-list${idx}`}
              />
            ) : (
              <CollapsableFilterGroup
                filterGroupData={groupData as CollapsableGroupData}
                listHeader={groupName}
                key={`collapsable-filter-group${idx}`}
              />
            ),
          )}
        </div>
      </OverlayScrollbarsComponent>
    </nav>
  );

  return isMobile ? (
    <>
      <div className={`aside-filters-mobile-container${filtersOpen ? ' open' : ''}`}>
        {componentOutput}
      </div>
    </>
  ) : (
    componentOutput
  );
};

export default CollapsableFilterGroupList;
