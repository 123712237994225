export const HOME_PAGINATION = {
  PREVIOUS: -1,
  NEXT: 1,
};

export const HOME_PROJECTS_LOADING_STEP = 15;

export enum CONTINENT {
  Africa = 'Africa',
  Asia = 'Asia',
  Europe = 'Europe',
  MiddleEast = 'Middle East',
  NorthAmerica = 'North America',
  SEAsia = 'SE Asia',
  SouthAmerica = 'South America',
}

export const COUNTRIES = {
  CI: { name: 'Ivory Coast', continent: CONTINENT.Africa },
  DZ: { name: 'Algeria', continent: CONTINENT.Africa },
  AO: { name: 'Angola', continent: CONTINENT.Africa },
  EG: { name: 'Egypt', continent: CONTINENT.Africa },
  ET: { name: 'Ethiopia', continent: CONTINENT.Africa },
  GH: { name: 'Ghana', continent: CONTINENT.Africa },
  KE: { name: 'Kenya', continent: CONTINENT.Africa },
  NG: { name: 'Nigeria', continent: CONTINENT.Africa },
  SN: { name: 'Senegal', continent: CONTINENT.Africa },
  ZA: { name: 'South Africa', continent: CONTINENT.Africa },
  TZ: { name: 'Tanzania', continent: CONTINENT.Africa },
  IQ: { name: 'Iraq', continent: CONTINENT.MiddleEast },
  CN: { name: 'China', continent: CONTINENT.Asia },
  IN: { name: 'India', continent: CONTINENT.Asia },
  ID: { name: 'Indonesia', continent: CONTINENT.SEAsia },
  IR: { name: 'Iran', continent: CONTINENT.Asia },
  JP: { name: 'Japan', continent: CONTINENT.Asia },
  MY: { name: 'Malaysia', continent: CONTINENT.SEAsia },
  PH: { name: 'Philippines', continent: CONTINENT.SEAsia },
  SG: { name: 'Singapore', continent: CONTINENT.SEAsia },
  KR: { name: 'South Korea', continent: CONTINENT.Asia },
  VN: { name: 'Vietnam', continent: CONTINENT.SEAsia },
  AT: { name: 'Austria', continent: CONTINENT.Europe },
  BE: { name: 'Belgium', continent: CONTINENT.Europe },
  FR: { name: 'France', continent: CONTINENT.Europe },
  DE: { name: 'Germany', continent: CONTINENT.Europe },
  GR: { name: 'Greece', continent: CONTINENT.Europe },
  IT: { name: 'Italy', continent: CONTINENT.Europe },
  NL: { name: 'Netherlands', continent: CONTINENT.Europe },
  ES: { name: 'Spain', continent: CONTINENT.Europe },
  SE: { name: 'Sweden', continent: CONTINENT.Europe },
  GB: { name: 'United Kingdom', continent: CONTINENT.Europe },
  BS: { name: 'Bahamas', continent: CONTINENT.NorthAmerica },
  BB: { name: 'Barbados', continent: CONTINENT.NorthAmerica },
  CA: { name: 'Canada', continent: CONTINENT.NorthAmerica },
  CR: { name: 'Costa Rica', continent: CONTINENT.NorthAmerica },
  CU: { name: 'Cuba', continent: CONTINENT.NorthAmerica },
  DO: { name: 'Dominican Republic', continent: CONTINENT.NorthAmerica },
  GT: { name: 'Guatemala', continent: CONTINENT.NorthAmerica },
  JM: { name: 'Jamaica', continent: CONTINENT.NorthAmerica },
  MX: { name: 'Mexico', continent: CONTINENT.NorthAmerica },
  US: { name: 'United States', continent: CONTINENT.NorthAmerica },
  AR: { name: 'Argentina', continent: CONTINENT.SouthAmerica },
  BO: { name: 'Bolivia', continent: CONTINENT.SouthAmerica },
  BR: { name: 'Brazil', continent: CONTINENT.SouthAmerica },
  CL: { name: 'Chile', continent: CONTINENT.SouthAmerica },
  CO: { name: 'Colombia', continent: CONTINENT.SouthAmerica },
  EC: { name: 'Ecuador', continent: CONTINENT.SouthAmerica },
  GY: { name: 'Guyana', continent: CONTINENT.SouthAmerica },
  PE: { name: 'Peru', continent: CONTINENT.SouthAmerica },
  PY: { name: 'Paraguay', continent: CONTINENT.SouthAmerica },
  UY: { name: 'Uruguay', continent: CONTINENT.SouthAmerica },
  AU: { name: 'Australia', continent: CONTINENT.SEAsia },
  FJ: { name: 'Fiji', continent: CONTINENT.SEAsia },
  KI: { name: 'Kiribati', continent: CONTINENT.SEAsia },
  MH: { name: 'Marshall Islands', continent: CONTINENT.SEAsia },
  NR: { name: 'Nauru', continent: CONTINENT.SEAsia },
  NZ: { name: 'New Zealand', continent: CONTINENT.SEAsia },
  PG: { name: 'Papua New Guinea', continent: CONTINENT.SEAsia },
  SB: { name: 'Solomon Islands', continent: CONTINENT.SEAsia },
  TO: { name: 'Tonga', continent: CONTINENT.SEAsia },
  VU: { name: 'Vanuatu', continent: CONTINENT.SEAsia },
  AF: { name: 'Afghanistan', continent: CONTINENT.Asia },
  AL: { name: 'Albania', continent: CONTINENT.Europe },
  AD: { name: 'Andorra', continent: CONTINENT.Europe },
  AG: { name: 'Antigua and Barbuda', continent: CONTINENT.NorthAmerica },
  AM: { name: 'Armenia', continent: CONTINENT.Asia },
  AZ: { name: 'Azerbaijan', continent: CONTINENT.Asia },
  BD: { name: 'Bangladesh', continent: CONTINENT.Asia },
  BH: { name: 'Bahrain', continent: CONTINENT.Asia },
  BY: { name: 'Belarus', continent: CONTINENT.Europe },
  BZ: { name: 'Belize', continent: CONTINENT.NorthAmerica },
  BJ: { name: 'Benin', continent: CONTINENT.Africa },
  BT: { name: 'Bhutan', continent: CONTINENT.Asia },
  BW: { name: 'Botswana', continent: CONTINENT.Africa },
  BN: { name: 'Brunei', continent: CONTINENT.SEAsia },
  BG: { name: 'Bulgaria', continent: CONTINENT.Europe },
  BF: { name: 'Burkina Faso', continent: CONTINENT.Africa },
  BI: { name: 'Burundi', continent: CONTINENT.Africa },
  CV: { name: 'Cabo Verde', continent: CONTINENT.Africa },
  KH: { name: 'Cambodia', continent: CONTINENT.Asia },
  CM: { name: 'Cameroon', continent: CONTINENT.Africa },
  TD: { name: 'Chad', continent: CONTINENT.Africa },
  KM: { name: 'Comoros', continent: CONTINENT.Africa },
  CG: { name: 'Congo', continent: CONTINENT.Africa },
  CD: { name: 'Congo (DRC)', continent: CONTINENT.Africa },
  HR: { name: 'Croatia', continent: CONTINENT.Europe },
  CY: { name: 'Cyprus', continent: CONTINENT.Asia },
  CZ: { name: 'Czech Republic', continent: CONTINENT.Europe },
  DK: { name: 'Denmark', continent: CONTINENT.Europe },
  DJ: { name: 'Djibouti', continent: CONTINENT.Africa },
  DM: { name: 'Dominica', continent: CONTINENT.NorthAmerica },
  SV: { name: 'El Salvador', continent: CONTINENT.NorthAmerica },
  GQ: { name: 'Equatorial Guinea', continent: CONTINENT.Africa },
  ER: { name: 'Eritrea', continent: CONTINENT.Africa },
  EE: { name: 'Estonia', continent: CONTINENT.Europe },
  SZ: { name: 'Eswatini', continent: CONTINENT.Africa },
  FI: { name: 'Finland', continent: CONTINENT.Europe },
  GA: { name: 'Gabon', continent: CONTINENT.Africa },
  GM: { name: 'Gambia', continent: CONTINENT.Africa },
  GE: { name: 'Georgia', continent: CONTINENT.Asia },
  GL: { name: 'Greenland', continent: CONTINENT.NorthAmerica },
  GD: { name: 'Grenada', continent: CONTINENT.NorthAmerica },
  GN: { name: 'Guinea', continent: CONTINENT.Africa },
  GW: { name: 'Guinea-Bissau', continent: CONTINENT.Africa },
  HT: { name: 'Haiti', continent: CONTINENT.NorthAmerica },
  HN: { name: 'Honduras', continent: CONTINENT.NorthAmerica },
  HU: { name: 'Hungary', continent: CONTINENT.Europe },
  IS: { name: 'Iceland', continent: CONTINENT.Europe },
  IL: { name: 'Israel', continent: CONTINENT.Asia },
  JO: { name: 'Jordan', continent: CONTINENT.Asia },
  KZ: { name: 'Kazakhstan', continent: CONTINENT.Asia },
  KW: { name: 'Kuwait', continent: CONTINENT.MiddleEast },
  KG: { name: 'Kyrgyzstan', continent: CONTINENT.Asia },
  LA: { name: 'Laos', continent: CONTINENT.Asia },
  LV: { name: 'Latvia', continent: CONTINENT.Europe },
  LB: { name: 'Lebanon', continent: CONTINENT.Asia },
  LS: { name: 'Lesotho', continent: CONTINENT.Africa },
  LR: { name: 'Liberia', continent: CONTINENT.Africa },
  LY: { name: 'Libya', continent: CONTINENT.Africa },
  LI: { name: 'Liechtenstein', continent: CONTINENT.Europe },
  LT: { name: 'Lithuania', continent: CONTINENT.Europe },
  LU: { name: 'Luxembourg', continent: CONTINENT.Europe },
  MG: { name: 'Madagascar', continent: CONTINENT.Africa },
  MW: { name: 'Malawi', continent: CONTINENT.Africa },
  MV: { name: 'Maldives', continent: CONTINENT.Asia },
  ML: { name: 'Mali', continent: CONTINENT.Africa },
  MT: { name: 'Malta', continent: CONTINENT.Europe },
  MR: { name: 'Mauritania', continent: CONTINENT.Africa },
  MU: { name: 'Mauritius', continent: CONTINENT.Africa },
  FM: { name: 'Micronesia', continent: CONTINENT.SEAsia },
  MC: { name: 'Monaco', continent: CONTINENT.Europe },
  MN: { name: 'Mongolia', continent: CONTINENT.Asia },
  ME: { name: 'Montenegro', continent: CONTINENT.Europe },
  MA: { name: 'Morocco', continent: CONTINENT.Africa },
  MZ: { name: 'Mozambique', continent: CONTINENT.Africa },
  MM: { name: 'Myanmar', continent: CONTINENT.SEAsia },
  NA: { name: 'Namibia', continent: CONTINENT.Africa },
  NP: { name: 'Nepal', continent: CONTINENT.Asia },
  NI: { name: 'Nicaragua', continent: CONTINENT.NorthAmerica },
  NE: { name: 'Niger', continent: CONTINENT.Africa },
  NO: { name: 'Norway', continent: CONTINENT.Europe },
  OM: { name: 'Oman', continent: CONTINENT.MiddleEast },
  PK: { name: 'Pakistan', continent: CONTINENT.MiddleEast },
  PW: { name: 'Palau', continent: CONTINENT.SEAsia },
  PA: { name: 'Panama', continent: CONTINENT.NorthAmerica },
  QA: { name: 'Qatar', continent: CONTINENT.MiddleEast },
  RO: { name: 'Romania', continent: CONTINENT.Europe },
  RU: { name: 'Russia', continent: CONTINENT.Asia },
  RW: { name: 'Rwanda', continent: CONTINENT.Africa },
  WS: { name: 'Samoa', continent: CONTINENT.SEAsia },
  ST: { name: 'Sao Tome and Principe', continent: CONTINENT.Africa },
  SA: { name: 'Saudi Arabia', continent: CONTINENT.MiddleEast },
  SC: { name: 'Seychelles', continent: CONTINENT.Africa },
  SL: { name: 'Sierra Leone', continent: CONTINENT.Africa },
  SK: { name: 'Slovakia', continent: CONTINENT.Europe },
  SI: { name: 'Slovenia', continent: CONTINENT.Europe },
  SO: { name: 'Somalia', continent: CONTINENT.Africa },
  SS: { name: 'South Sudan', continent: CONTINENT.Africa },
  SD: { name: 'Sudan', continent: CONTINENT.Africa },
  SR: { name: 'Suriname', continent: CONTINENT.SouthAmerica },
  SY: { name: 'Syria', continent: CONTINENT.Asia },
  TJ: { name: 'Tajikistan', continent: CONTINENT.Asia },
  TH: { name: 'Thailand', continent: CONTINENT.SEAsia },
  TL: { name: 'Timor-Leste', continent: CONTINENT.Asia },
  TG: { name: 'Togo', continent: CONTINENT.Africa },
  TT: { name: 'Trinidad and Tobago', continent: CONTINENT.NorthAmerica },
  TN: { name: 'Tunisia', continent: CONTINENT.Africa },
  TR: { name: 'Türkiye', continent: CONTINENT.Europe },
  TM: { name: 'Turkmenistan', continent: CONTINENT.Asia },
  TV: { name: 'Tuvalu', continent: CONTINENT.SEAsia },
  UA: { name: 'Ukraine', continent: CONTINENT.Europe },
  AE: { name: 'United Arab Emirates', continent: CONTINENT.MiddleEast },
  UZ: { name: 'Uzbekistan', continent: CONTINENT.Asia },
  VA: { name: 'Vatican City', continent: CONTINENT.Europe },
  VE: { name: 'Venezuela', continent: CONTINENT.SouthAmerica },
  YE: { name: 'Yemen', continent: CONTINENT.Asia },
  ZM: { name: 'Zambia', continent: CONTINENT.Africa },
  ZW: { name: 'Zimbabwe', continent: CONTINENT.Africa },
  PL: { name: 'Poland', continent: CONTINENT.Europe },
  VG: { name: 'Virgin Islands, British', continent: CONTINENT.NorthAmerica },
};
