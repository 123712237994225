import { ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../features/state/hooks';
import {
  clearSearchCriteria,
  clearSearchResults,
  setCreateDateOption,
  setCustomerName,
  setFieldSalesOrder,
  setFiltersSelected,
  setJobSalesOrder,
  setPagesRangeUpdateRequired,
  setProjectId,
  setProjectTitle,
  setQuoteId,
  setRefreshSearch,
  setRegion,
  setRtaNumber,
  setSearchCriteriaChanged,
  setSearchLoading,
  setSearchResults,
  setShowResults,
  setSkip,
  setStatusOption,
} from '../../../features/state/slices/searchSlice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useApi } from '../../../api/ApiContext';
import { searchProjects } from '../../../api/endpoints/projects';
import { CreateDateOption, StatusOption } from '../../../constants/projectItem';
import SearchResult, { ISearchResult } from '../../../models/searchResult';

export const CategorySearch = () => {
  const dispatch = useAppDispatch();
  const { apiInstance } = useApi();
  const {
    search,
    refreshSearch,
    filtersSelected,
    searchCriteriaChanged,
    showResults: searched,
  } = useAppSelector((state) => state.search.value);

  const searchButtons = (screen: string) => (
    <div className={`search-buttons ${screen}`}>
      <div className={`btn ${filtersSelected || searched ? 'enabled' : ''}`} onClick={clearFilters}>
        Clear All
      </div>
      <div className={`btn ${searchCriteriaChanged ? 'enabled' : ''}`} onClick={showResultsClicked}>
        Show Results
      </div>
    </div>
  );

  const clearFilters = () => {
    if (!filtersSelected && !searched) return;
    dispatch(setFiltersSelected(false));
    dispatch(setSearchCriteriaChanged(true));
    dispatch(clearSearchCriteria());
    dispatch(clearSearchResults());
    dispatch(setShowResults(false));
  };

  const handleSearchCriteriaChange = (
    e: ChangeEvent<HTMLInputElement> | string,
    update: ActionCreatorWithPayload<string>,
  ) => {
    dispatch(setSearchCriteriaChanged(true));
    dispatch(setFiltersSelected(true));
    dispatch(update(typeof e === 'string' ? e : e.target.value)); // This works because we either get string or object at runtime
  };

  const showResults = () => {
    dispatch(setSearchLoading(true));
    searchProjects(apiInstance, search)
      .then((response) => {
        const searchResults: ISearchResult[] = response.responseAsJson.projects.map(
          (project: ISearchResult) => SearchResult.fromJsonToReduxJson(project),
        );
        dispatch(
          setSearchResults({
            projects: searchResults,
            resultsStart: response.responseAsJson.resultsStart,
            resultsEnd: response.responseAsJson.resultsEnd,
            totalResults: response.responseAsJson.totalResults,
          }),
        );
      })
      .finally(() => {
        dispatch(setSearchLoading(false));
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Search: Error showing results', error);
        dispatch(clearSearchResults());
        dispatch(setShowResults(true));
      });
  };
  const showResultsClicked = () => {
    if (!searchCriteriaChanged) return;
    dispatch(setPagesRangeUpdateRequired(true));
    dispatch(setSearchCriteriaChanged(false));
    dispatch(setSkip(0));
    dispatch(setRefreshSearch(true));
  };

  const onKeyPressed = (e: KeyboardEvent) => {
    if (e.key === 'Enter') showResultsClicked();
  };
  useEffect(() => {
    if (refreshSearch) {
      showResults();
      dispatch(setRefreshSearch(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSearch]);

  useEffect(() => {
    if (searchCriteriaChanged) {
      window.addEventListener('keydown', onKeyPressed);
      return () => window.removeEventListener('keydown', onKeyPressed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteriaChanged]);

  return (
    <div className="filters-container">
      <div className="header">
        <div className="title">
          <h1>Category search</h1>
          <p>
            Quickly find the project you are looking for by selecting from the predefined options
            below.
          </p>
        </div>
        {searchButtons('desktop')}
      </div>
      <hr />
      <div className="filters">
        <div className="input-filters">
          <div className="filter-text">
            <h3>Project title</h3>
            <input
              type="text"
              placeholder="Enter Project title"
              value={search.projectTitle}
              onChange={(e) => handleSearchCriteriaChange(e, setProjectTitle)}
            />
          </div>
          <div className="filter-text">
            <h3>Project ID</h3>
            <input
              type="text"
              placeholder="Enter project ID"
              value={search.projectId}
              onChange={(e) => handleSearchCriteriaChange(e, setProjectId)}
            />
          </div>
          <div className="filter-text">
            <h3>Region</h3>
            <input
              type="text"
              placeholder="Enter region"
              value={search.region}
              onChange={(e) => handleSearchCriteriaChange(e, setRegion)}
            />
          </div>
          <div className="filter-text">
            <h3>Customer name</h3>
            <input
              type="text"
              placeholder="Enter customer name"
              value={search.customerName}
              onChange={(e) => handleSearchCriteriaChange(e, setCustomerName)}
            />
          </div>
          <div className="filter-text">
            <h3>Quote ID</h3>
            <input
              type="text"
              placeholder="Enter quote ID"
              value={search.quoteId}
              onChange={(e) => handleSearchCriteriaChange(e, setQuoteId)}
            />
          </div>
          <div className="filter-text">
            <h3>RTA number</h3>
            <input
              type="text"
              placeholder="Enter RTA number"
              value={search.rtaNumber}
              onChange={(e) => handleSearchCriteriaChange(e, setRtaNumber)}
            />
          </div>
          <div className="filter-text">
            <h3>Field sales order</h3>
            <input
              type="text"
              placeholder="Enter field sales order"
              value={search.fieldSalesOrder}
              onChange={(e) => handleSearchCriteriaChange(e, setFieldSalesOrder)}
            />
          </div>
          <div className="filter-text">
            <h3>Job sales order</h3>
            <input
              type="text"
              placeholder="Enter job sales order"
              value={search.jobSalesOrder}
              onChange={(e) => handleSearchCriteriaChange(e, setJobSalesOrder)}
            />
          </div>
        </div>
        <div className="toggleable-filters">
          <div className="creation-date">
            <h3>Creation date</h3>
            <div className="items">
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.OneDay ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.OneDay, setCreateDateOption)
                }>
                24 HR
              </div>
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.WeekToDate ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.WeekToDate, setCreateDateOption)
                }>
                WTD
              </div>
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.MonthToDate ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.MonthToDate, setCreateDateOption)
                }>
                MTD
              </div>
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.SixMonth ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.SixMonth, setCreateDateOption)
                }>
                6 MTH
              </div>
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.YearToDate ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.YearToDate, setCreateDateOption)
                }>
                YTD
              </div>
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.OneYear ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.OneYear, setCreateDateOption)
                }>
                1 YR
              </div>
              <div
                className={`toggle ${
                  search.createDateOption === CreateDateOption.All ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(CreateDateOption.All, setCreateDateOption)
                }>
                ALL
              </div>
            </div>
          </div>
          <div className="status">
            <h3>Status</h3>
            <div className="items">
              <div
                className={`toggle initiation ${
                  search.statusOption === StatusOption.Initiation ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(StatusOption.Initiation, setStatusOption)
                }>
                Initiation
              </div>
              <div
                className={`toggle technology ${
                  search.statusOption === StatusOption.Technology ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(StatusOption.Technology, setStatusOption)
                }>
                Technology
              </div>
              <div
                className={`toggle manufacturing ${
                  search.statusOption === StatusOption.Manufacturing ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(StatusOption.Manufacturing, setStatusOption)
                }>
                Manufacturing
              </div>
              <div
                className={`toggle operations ${
                  search.statusOption === StatusOption.Operations ? 'selected' : ''
                }`}
                onClick={() =>
                  handleSearchCriteriaChange(StatusOption.Operations, setStatusOption)
                }>
                Operations
              </div>
              <div
                className={`toggle canceled ${
                  search.statusOption === StatusOption.Canceled ? 'selected' : ''
                }`}
                onClick={() => handleSearchCriteriaChange(StatusOption.Canceled, setStatusOption)}>
                Canceled
              </div>
              <div
                className={`toggle complete ${
                  search.statusOption === StatusOption.Complete ? 'selected' : ''
                }`}
                onClick={() => handleSearchCriteriaChange(StatusOption.Complete, setStatusOption)}>
                Complete
              </div>
              <div
                className={`toggle all ${
                  search.statusOption === StatusOption.All ? 'selected' : ''
                }`}
                onClick={() => handleSearchCriteriaChange(StatusOption.All, setStatusOption)}>
                All
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchButtons('mobile')}
    </div>
  );
};
