import { CollapsableEntity } from '../../types/CollapsableFilterTypes';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../features/state/hooks';
import {
  setCollapsableFilter,
  setGroupCollapsed,
  setGroupExpanded,
  setProjectsConfigSelectedCountry,
  setProjectsConfigSkip,
  clearProjects,
  setProjectsShownAmount,
} from '../../features/state/slices/homeSlice';
import { HOME_PROJECTS_LOADING_STEP } from '../../constants/home';

const CollapsableFilter = ({
  arrOfEntities,
  sectionName,
}: {
  arrOfEntities: Array<CollapsableEntity>;
  sectionName: string;
}) => {
  const collapsableFilter = useAppSelector((state) => state.home.value.hideableFilter);
  const selectedCountryId = useAppSelector(
    (state) => state.home.value.projectsConfig.selectedCountry,
  );
  const dispatch = useAppDispatch();
  const isOpen: boolean = collapsableFilter.expandedGroups.some(
    (groupName) => groupName === sectionName,
  );
  return (
    <div className={'aside-filter-section-container' + (isOpen ? ' open' : '')}>
      <div
        className="expandable-section-group aside-filter-section-name"
        onClick={() => {
          const actionToDispatch = isOpen ? setGroupCollapsed : setGroupExpanded;
          dispatch(actionToDispatch(sectionName));
        }}>
        {sectionName}
      </div>
      <div className="aside-filter-options-list-container">
        <ul className="aside-filter-options-list">
          {arrOfEntities.map((entity) => (
            <li
              key={entity.id as React.Key}
              className={
                selectedCountryId === entity.id && collapsableFilter.selectedGroup === sectionName
                  ? 'selected'
                  : ''
              }
              onClick={() => {
                if (
                  selectedCountryId === entity.id &&
                  collapsableFilter.selectedGroup === sectionName
                ) {
                  // Unselect the filter
                  dispatch(
                    setCollapsableFilter({
                      selectedGroup: '',
                      selectedFilterName: '',
                    }),
                  );
                  dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
                  dispatch(clearProjects());
                  dispatch(setProjectsConfigSelectedCountry(''));
                  dispatch(setProjectsConfigSkip(0));
                  return;
                }
                dispatch(
                  setCollapsableFilter({
                    selectedGroup: sectionName,
                    selectedFilterName: entity.name,
                  }),
                );

                dispatch(setProjectsShownAmount(HOME_PROJECTS_LOADING_STEP));
                dispatch(clearProjects());
                dispatch(setProjectsConfigSelectedCountry(entity.id));
                dispatch(setProjectsConfigSkip(0));
              }}>
              {entity.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CollapsableFilter;
