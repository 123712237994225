import { Button } from 'react-bootstrap';

import HalLogo from '../assets/images/hal-logo.svg';
import { useOktaAuthContext } from '../features/auth/oktaAuth';

const Landing = () => {
  const { openOktaModal } = useOktaAuthContext();

  const login = () => {
    openOktaModal();
  };

  return (
    <div className="landing-page-container">
      <div className="hal-title">
        <img src={HalLogo} alt="Halliburton Logo" />
      </div>
      <section className="login-container">
        <div className="title-container">
          <h1>Welcome to</h1>
          <h2 className="title-logo">HalTrax</h2>
        </div>
        <div className="login-cta-container">
          <Button className="landing-log-in-btn" onClick={login}>
            Login
          </Button>
        </div>
      </section>
    </div>
  );
};

export default Landing;
