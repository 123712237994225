import { ProjectItem } from '../../types/ProjectTypes';
import ProjectIndexProgressBar from './ProjectIndexProgressBar';
import { capitalizeFirstLetter } from '../../utils/naming';
import { useNavigate } from 'react-router-dom';

const ProjectCard = ({
  projectId,
  projectType,
  wellsAmount,
  projectName,
  projectStatus,
  quoteCount,
  projectProgress,
  estimatedOnSiteDate,
  quoteId,
  fieldSalesOrder,
}: ProjectItem) => {
  const navigate = useNavigate();
  const projectProgressArray = Object.entries(projectProgress);
  const overallProjectProgressValue = Math.ceil(
    projectProgressArray.reduce((prevVal, [, indexPrevValue]) => prevVal + indexPrevValue, 0) /
      projectProgressArray.length,
  );

  let formattedDate = estimatedOnSiteDate;
  if (!!estimatedOnSiteDate && !isNaN(Date.parse(estimatedOnSiteDate))) {
    formattedDate = new Date(estimatedOnSiteDate).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  }

  const stageShouldBeDisabled = (percentage: number): boolean => {
    const initiationStageComplete = projectProgress.initiation === 100;
    const stagesNotStarted =
      projectProgress.manufacturing === 0 &&
      projectProgress.operations === 0 &&
      projectProgress.technology === 0;
    const currentStageStarted = percentage > 0;

    if (initiationStageComplete && stagesNotStarted) {
      return false;
    }

    if (currentStageStarted) {
      return false;
    }

    return true;
  };

  return (
    <div className="project-card-container" onClick={() => navigate(`/project/${projectId}`)}>
      <h3>{projectName}</h3>
      <div className="project-values-container">
        <span className="project-overall-progress">{overallProjectProgressValue}%</span>
        <ul className="project-values">
          <li>
            <span className="project-value-name">Est. on site</span>
            <span className="project-value">{formattedDate}</span>
          </li>
          <li className="hover-tooltip-row">
            <span className="project-value-name">Quote ID</span>
            <span className="project-value">{quoteId}</span>
            {quoteCount > 1 && (
              <div className="hover-tooltip-content">
                This project contains multiple Quote IDs. Click here for additional project details.
              </div>
            )}
          </li>
          <li>
            <span className="project-value-name">Manufacturing SO ID</span>
            <span className="project-value">{fieldSalesOrder}</span>
          </li>
        </ul>
      </div>
      <div className="project-card-title-indexes-container">
        <div className="project-card-indexes-container">
          {Object.entries(projectProgress).map(([indexName, percentage]) => (
            <ProjectIndexProgressBar
              key={indexName + percentage}
              indexName={capitalizeFirstLetter(indexName)}
              indexValue={percentage}
              disabled={stageShouldBeDisabled(percentage)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
